import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from "axios"
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react"
import * as Constants from './Constants'
import { useNavigate } from 'react-router-dom'
import {motion, AnimatePresence} from 'framer-motion'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../components/Loader";
import 'react-toastify/dist/ReactToastify.css';
import { Trans, useTranslation } from 'react-i18next';




const Header = () => {
  const [categories, setCategories] = useState([]);
  const [searchInput, setSearchInput] = useState('none');
  const [searchWord, setSearchWord] = useState('');
  const [searchFilteringData, setSearchFilteringData] = useState([]);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const { register, handleSubmit, reset } = useForm(); 
  const [settings, setSetting] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/categories`).then(res => {
        if (res.data.status === 200) {
          setCategories(res.data.categories);
        }
      }).catch();

  }, []);

  useEffect(() => {    
  axios.get(`${Constants.LARAVEL_API_URL}/getSettings`).then(res => {
      if (res.data.status === 200) {
          setLoading(false);
          setSetting(res.data.settings);
      }
      }).catch();
  }, []);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  //Search
  const searchHandler = async (event) => {
    const searchWord = event.target.value;
    setSearchWord(searchWord);
    if (searchWord.length > 3) {
        const { data } = await axios.get(`${Constants.LARAVEL_API_URL}/searchresult/${searchWord}`);       
        if(data.products){
            setSearchFilteringData(data.products.slice(0,30));
            setSearchInput('block');
        }
    } else {
        setSearchFilteringData([]);
        setSearchInput('none');
    }
  }

  const menuHandler = () => {
    setOpenMenu(!openMenu);
  }
  const menuHandler2 = () => {
    setOpenMenu2(!openMenu2);
  }


  let searchList = '';
  searchList = searchFilteringData.slice(0, 10).map((item, idx) => {
      return (
          <div key={idx}>
              { item.searchCategory === null ? ' ' :
                  <Link to={`/product/${item.slug}`} className="headerSearchItem">
                      <img className="homeMenuImg" src={`${Constants.LARAVEL_URL}/assets/images/products/${item.images}`} height={30} width={30} alt={item.name} />
                      <p>{item.name}</p>
                  </Link>
              }
          </div>
      )
  });// end search

  const handleSearchBtn = () => {
    navigate(`/searchresult/${searchWord}`);
  }

  const modalRef = useRef()

  const onSubmit = async (data) => {
    await axios.post(`${Constants.LARAVEL_API_URL}/setOrder`, data)
    .then(response => {
        if(response.data.status === 200){
          toast.success(t('your_order_success'));

          reset();

          window.setTimeout(function () {
            modalRef.current.close();
          }, 2000);
        }
    })
    .catch(function (error) {
        toast.error(t('your_order_error'));
    });
  };

  //Translation
  const languages = [
    {code: 'ru', lang: "РУ"},
    {code: 'en', lang: "EN"},
    {code: 'kz', lang: "КЗ"},
  ]
  const {t, i18n} = useTranslation();

  const changeLanguage = (option) => {
    i18n.changeLanguage(option.target.value);
    window.location.reload();
  };

  const submit_btn = t('submit_btn');

  const lang = i18n.language || 'ru';

  return (
    <header>
        <Modal ref={modalRef}>
          <h2>{t('set_your_order_title')}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="inputField">
                    <i class="fa-solid fa-user"></i>
                    <input                        
                        required
                        className="modalInput"
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t('modal_name')}
                        autoComplete='off'
                        {...register("name")}
                    />
                </fieldset>                    
                <fieldset className="inputField">
                    <i class="fa-solid fa-phone-volume"></i>
                    <input
                        required
                        className="modalInput"
                        type="phone"
                        id="phone"
                        name="phone"
                        autoComplete='off'
                        placeholder={t('modal_phone')}
                        {...register("phone")}
                    />
                </fieldset>
                <fieldset className="inputField">
                    <i class="fas fa-envelope"></i>
                    <input
                        required
                        className="modalInput"
                        type="email"
                        id="email"
                        name="email"
                        autoComplete='off'
                        placeholder={t('modal_email')}
                        {...register("email")}
                    />
                </fieldset>
                <fieldset className="inputField">
                    <i class="fas fa-comment"></i>
                    <textarea
                        required
                        className="modalInput modalTextarea"
                        type="text"
                        id="comment"
                        name="comment"
                        autoComplete='off'
                        placeholder={t('modal_comment')}
                        {...register("comment")}
                    />
                </fieldset>
                <button type="submit" className="modalInputBtn">{t('send_request_btn')}</button>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div 
                className='modalClose'
                onClick={() => {modalRef.current.close();}}
                >x</div>
            </form> 
        </Modal>
        <div className="headerHeadTopWrap">
          <div className="container">
            <div className="headerHeadTop">
              <nav>
                <ul>
                  <li>
                    <NavLink
                    to="/license"
                    >{t('licence_page_title')}</NavLink>
                  </li>
                  <li>
                    <NavLink 
                    to="/about"
                    >{t('about_page_title')}</NavLink>
                  </li>
                  <li>
                    <NavLink 
                    to="/contacts"
                    >{t('contacts_page_title')}</NavLink>
                  </li>
                </ul>
              </nav>
              <div className="headerHeadTopRight">
                  <div className="headerHeadLink">
                      <i className="fas fa-phone-volume" aria-hidden="true"></i>
                      <a href={`tel:${settings.phone}`}>{settings.phone}</a>
                  </div>
                  <div className="headerHeadLink">
                    <i className="fas fa-envelope" aria-hidden="true"></i>
                      <a href={`mailto:${settings.email}`}>{settings.email}</a>
                  </div>
                  <div className="headerLang">
                    <select className='selectLang' onChange={changeLanguage} value={lang}>
                          <option value='ru'>РУ</option>
                          <option value='en'>EN</option>
                          <option value='kz'>КЗ</option>
                    </select>
                  </div>
              </div>
            </div>
          </div>          
        </div>
        <div className="headerHeadWrap">
            <div className="container">
              <div className="headerHead">
                <div className="headerLogo">
                  <NavLink to="/" >
                    <img
                      src="/uploads/images/headerLogo.png"
                      width={266}
                      height={52}
                      className='headerLogoMobile'
                      alt="Оптические технологии"
                    />
                  </NavLink>
                  <p>{t("slogan")}</p>
                  <div className='logoSloganLong'>{t('distributor_slogan')}</div>
                </div>
                <div className={ i18n.language === 'kz' ? 'headerHeadNav kazakhLanguage' : 'headerHeadNav'}>
                <motion.nav
                  initial={{ y: -100 }}
                  animate={{ y: isSticky ? -70 : 0 }}
                  transition={{ type: 'spring', stiffness: 80 }}
                  className={`fixed top-0 left-0 w-full z-50 bg-white shadow-lg ${
                    isSticky ? 'sticky' : ''
                  }`}
                >
                  <ul>
                      <li>
                        <NavLink to="/" className="">                          
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/brands" className="">{t('brands_page_title')}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/projects" className="">{t('projects_page_title')}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/articles" className="">{t('news_page_title')}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/solutions" className="">{t('solutions_page_title')}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services" className="">{t('service_page_title')}</NavLink>
                      </li>
                    </ul>
                </motion.nav>
                </div>
                <div 
                  className="headerHeadBtn"
                  onClick={() => modalRef.current.open()}
                ><Trans
                    i18nKey={submit_btn}
                    components={{1: <br />}}
                ></Trans></div>
              </div>
            </div>
        </div>
        <div className="headerNavSearchWrap">
          <div className="container">
            <div className="headerNavSearch">
              <div className="headerCatalogNav" id="catalogBtn">
                <div className="headerCatalogBtn" onClick={menuHandler}>
                    <div className={`header__burger ${openMenu ? 'active' : ''}`}>
                        <span></span>
                    </div>                            
                    <h4>{t('catalog_page_title')}</h4>
                </div>
                <div className={`header__menu ${openMenu ? 'active' : ''}`}>
                  <ul> 
                    {categories.map(category => (                    
                      <li key={category.id}>
                        <NavLink to={`/catalog/${category.slug}`} onClick={menuHandler}>
                          <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${category.image}`} alt={category.name} width={50}  />
                          <p>{category.name}</p>
                        </NavLink>
                        <ul>
                          {category.children && category.children.map(child => (
                            <li key={child.id}>
                              <NavLink to={`/catalog/${category.slug}/${child.slug}`} onClick={menuHandler}>
                                {child.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>                
              </div>
              <div className="headerSearch">
                <form>
                  <input 
                    type="text" 
                    required
                    placeholder={t('search_by_catalog')}
                    onChange={searchHandler}
                    onBlur={() =>
                        setTimeout(() => {
                            setSearchInput('none');
                        }, 100)
                    }
                  
                  />
                  <button type="submit" onClick={searchWord === '' ? null : handleSearchBtn}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></button>
                  <div className="headerSearchResult" style={{display: searchInput}} >
                      {searchList}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={`${openMenu ? 'modalOverlayMy' : ''}`} onClick={menuHandler}></div>
        <div className={`${openMenu2 ? 'modalOverlayMy' : ''}`} onClick={menuHandler2}></div>
        <div className='headerMobile'>
          <div className='headerMobileCatalog'>
              <div className="headerCatalogBtnMobile" >
                  <div className={`header__burger ${openMenu ? 'active' : ''}`} onClick={menuHandler}>
                      <span></span>
                  </div>
                  <div className={`header__menu__mobile ${openMenu ? 'active' : ''}`}>
                    <ul> 
                      {categories.map(category => (                    
                        <li key={category.id}  className="dropdown__item">
                          <NavLink to={`/catalog/${category.slug}`} onClick={menuHandler}>
                            <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${category.image}`} alt={category.name} width={50}  />
                            <p>{category.name}</p>
                          </NavLink>
                          <ul className='dropdown__menu'>
                            {category.children && category.children.map(child => (
                              <li key={child.id}>
                                <NavLink to={`/catalog/${category.slug}/${child.slug}`} onClick={menuHandler}>
                                  {child.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>   
              </div>
          </div>
          <div className='headerMobileData'>
            <div className='headerMobileDataBtn' onClick={menuHandler2}>Меню</div>
              <div className={`headerMobileDataContent ${openMenu2 ? 'active' : ''}`} >
                  <nav>
                    <ul>
                      <li>
                        <NavLink to="/" className=""><i className="fa-solid fa-house-chimney"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to="/brands" className="">Бренды</NavLink>
                      </li>
                      <li>
                        <NavLink to="/projects" className="">Проекты</NavLink>
                      </li>
                      <li>
                        <NavLink to="/articles" className="">Новости</NavLink>
                      </li>
                      <li>
                        <NavLink to="/solutions" className="">Решения</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services" className="">Сервис и услуги</NavLink>
                      </li>
                    </ul>
                  </nav>
                  
                  <div 
                    className="headerHeadMobileBtn"
                    onClick={() => modalRef.current.open()}
                  >оставить <br />заявку</div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header


const Modal = forwardRef(( props, ref ) => {  
    const [openModal, setOpenModal] = useState(false);

    useImperativeHandle(ref, () => {
      return{
        open: () => setOpenModal(true),
        close: () => setOpenModal(false)
      }
    })

    return (
      <AnimatePresence>
        {openModal && (
              <>
              <motion.div 
                  initial={{
                      opacity:0,
                  }}
                  animate={{
                      opacity:1,
                      transition:{
                          duration:0.3,
                      }
                  }}
                  exit={{
                      opacity:0,
                      transition:{
                          delay:0.3,
                      },
                  }}
                  onClick={() => setOpenModal(false)}
                  className='modal-backdrop' 
              />
              <motion.div 
                  initial={{
                      scale:0,
                  }}
                  animate={{
                      scale:1,
                      transition:{
                          duration:0.3,
                      },
                  }}
                  exit={{
                      scale:0,
                      transition:{
                          delay:0.3,
                      },
                  }}
                  className='modal-content-wrapper'
              >
                  <motion.div 
                      initial={{
                          x:100,
                          opacity:0,
                      }}
                      animate={{
                          x:0,
                          opacity:1,
                          transition:{
                              delay:0.3,
                              duration:0.3,
                          },
                      }}
                      exit={{
                          x:100,
                          opacity:0,
                          transition:{
                              duration:0.3,
                          },
                      }}
                      className='modal-content'
                  >
                      {props.children}
                  </motion.div>
              </motion.div>
          </>
          )}
      </AnimatePresence>
    )
  }
);

