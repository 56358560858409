import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import * as Constants from '../components/Constants';
import { useTranslation } from 'react-i18next';
import LicenseGallery from "../components/LicenseGallery";


const Licence = () => {
  const {t, i18n} = useTranslation();
  const [licenses, setLicense] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/getLicenses`).then(res => {
      if (res.data.status === 200) {
          setLoading(false);
          setLicense(res.data.licenses);
          
      }
    });
  }, []);
  
  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('licence_page_title')}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li>{t('licence_page_title')}</li>
          </ul>
        </div>
        <div className='licenseContent'>
          <LicenseGallery licenses={licenses} loading={loading} />
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default Licence