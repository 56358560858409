import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import HomeSlider from '../components/HomeSlider';
import * as Constants from '../components/Constants';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';


const Home = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [solcategories, setSolCategories] = useState([]);
  const [partners, setPartner] = useState([]);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/categories`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setCategories(res.data.categories);
        }
      }).catch();
  }, []);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/getPartners`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setPartner(res.data.partners);
          console.log(res.data.partners);
        }
      }).catch();
  }, []);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/articles`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setArticles(res.data.articles);
        }
      }).catch();
  }, []);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/solcategories`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setSolCategories(res.data.solcategories);
        }
      }).catch();
  }, []);

  let viewArticles = '';

  viewArticles = articles.slice(0, 4).map((item, idx) => {
    let updatedHtmlContent = '';
    if(item.description != null){
      updatedHtmlContent = item.description.split(' ').slice(0, 12).join(' ');
    }     
      return(
        
          <Link key={idx} to={`articles/${item.slug}`} className="articlesItem">
            <div className="articlesItemImgWrap">
                <img src={`${Constants.LARAVEL_URL}/assets/images/articles/${item.image}`} alt={item.title} className="articlesItemImg" />
            </div>
            <div className="articlesItemContent">
                <h4>{item.title}</h4>
                <div className='articlesItemContentText' dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}></div>
            </div>
          </Link>
      )
  });

  
  let viewSolCategories = '';
  viewSolCategories = solcategories.map((item, idx) => {
    return(
        <Link key={idx} to={`solutions/${item.slug}`} className="solutionItem">
          <img
              src={`${Constants.LARAVEL_URL}/assets/images/solcategories/${item.image}`}
              alt={`${item.title}`}
              width="293"
              height="189"
              className="solutionItemImg"
            />
            <p>{`${item.title}`}</p>
        </Link>
    )
  });

  let allPartners = '';
  allPartners = partners.map((item, idx) => {
    return(
      <img
        key={idx}
        src={`${Constants.LARAVEL_URL}/assets/images/partners/${item.image}`}
        alt="Наши партнеры"
        className="articlesItemImg"
      />
    )
  });

  
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay:true,
          arrows:false,
        }
      }
    ]
  };

  const {t, i18n} = useTranslation();

  return (
    <>
    <main>
      <Header />
      <section>   
        <div className="slider">     
          {loading ? <Loader width={400} height={400} /> : <HomeSlider />}
        </div>
      </section>
      <section className="homeBestWrap">
        <div className="container">
          <div className="homeBestCategories">
            {loading ? <Loader width={200} height={200} /> : categories.slice(0, -1).map((category)=>(
              <div className="homeBestCatItem" key={category.id}>
                <h3>{category.name}</h3>
                <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${category.image}`} alt={category.name} />
                <NavLink to={`/catalog/${category.slug}`} className="homeBestBtn">
                  {t('go_to_section')}
                </NavLink>
              </div>
            ))}
          </div>
          <div className="homeBestAboutNumbers">
            <div className="homeBestAbout">
              <h2 className="homeH2">{t('about_page_title')}</h2>
              <div className="homeBestAboutContent">
                <p>
                Компания ТОО «Оптические Технологии» — поставщик и интегратор технологических решений в области телекоммуникаций, энергетики и промышленной автоматизации. Компания реализует проекты для заказчиков, помогая предприятиям различных отраслей в Казахстане и странах Центральной Азии повысить операционную эффективность. Благодаря большому опыту и постоянному совершенствованию у компании сформировались отличительные преимущества, которые позволило стать официальным дистрибьютором ведущих мировых производителей.<br/><br/>
                ТОО «Оптические Технологии» сотрудничает с такими мировыми компаниями как Anritsu Corporation, Sumitomo Electric, Yokogawa Electric Corporation, Aaronia AG, Fluke, Rohde&Schwarz, Triathlon Batterien GmbH и тд.
                </p>
                <NavLink to="/about">
                  <div className="homeBestBtn">
                  {t('go_to_section')}
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="homeBestNumbers">
              <h2 className="homeH2">{t('bests_in_numbers_title')}</h2>
              <div className="homeBestNumberItemWrap">              
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                    11
                  </div>
                  <p><strong>11 лет</strong> опыта в оптических <br/> технологиях и решениях</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  1000+
                  </div>
                  <p>Проданно свыше <strong>1000</strong><br/> оборудования</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  30+
                  </div>
                  <p>Более <strong>30</strong> постоянных<br/>клиентов и партнеров</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  100+
                  </div>
                  <p>Более <strong>100</strong> Реализованных<br/> проектов</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="specialService">
          <div className="special">
            <NavLink to="/offers">
              <img
                src="uploads/images/special1.jpg"
                alt="Cпециальные предложения"
                width="640"
                height="244"
              />
            <div className="specialText">
              <p>{t('special_offers_title')}</p>
            </div>
            </NavLink>
          </div>
          <div className="special">
            <NavLink to="/services">
              <img
                src="uploads/images/special2.jpg"
                alt="Сервис и услуги"
                width="640"
                height="244"
              />
            <div className="specialText">
              <p>{t('service_page_title')}</p>
            </div>            
            </NavLink>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="solutionsWrap">
          <h2 className="homeH2">{t('solutions_page_title')}</h2>
        </div>
        <div className="solutionContent">
          {loading ? <Loader width={400} height={400} /> : viewSolCategories} 
        </div>
      </section>
      <section className="container">
        <div className="articlesWrap">
          <h2 className="homeH2">{t('news_page_title')}</h2>
        </div>
        <div className="articlesContent">
          {loading ? <Loader width={400} height={400} /> : viewArticles}          
        </div>
        <div className='homeNewsBtn'>
          <NavLink to="/articles">
              <div className="homeBestBtn">
              {t('news_page_title')}
              </div>
          </NavLink>
        </div>
      </section>
      <section>
        <div className="partnersWrap">
          <h2 className="homeH2">{t('partners_homepage_title')}</h2>
        </div>
        <div className="slider-container container">
        <Slider {...settings}>
          {loading ? <Loader width={400} height={400} /> : allPartners}          
        </Slider>
        </div>
      </section>
      </main>
      <Footer />
    </>
  )
}

export default Home


