import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import * as Constants from "./Constants";
import Loader from "../components/Loader";
import axios from "axios";

const HomeSlider = () => {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${Constants.LARAVEL_API_URL}/getSlider`)
      .then((res) => {
        if (res.data.status === 200) {
          setSliders(res.data.sliders);
          setLoading(false);
        }
      })
      .catch((err) => console.error("Ошибка загрузки слайдов:", err));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {loading ? (
          <Loader width={400} height={400} />
        ) : (
          sliders.map((item, idx) => (
            <div key={idx}>
              <img
                src={`${Constants.LARAVEL_URL}/assets/images/sliders/${item.image}`}
                alt=""
              />
            </div>
          ))
        )}
      </Slider>
    </>
  );
};

export default HomeSlider;
