import React from 'react';
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import * as Constants from '../components/Constants'



const LicenseGallery = ({ licenses, loading }) => {
    const licenseTypes = [
        { type: 1, title: "Лицензии" },
        { type: 2, title: "Сертификаты ИСО" },
        { type: 3, title: "Авторизационное письмо" },
      ];
    
      const renderLicensesByType = (type) => {
        const filteredLicenses = licenses?.filter((license) => license.type === type);
    
        return loading ? (
          <Loader width={400} height={400} />
        ) : filteredLicenses.length > 0 ? (
          filteredLicenses.map((license) => (
            <Link
              key={license.id}
              to={`${Constants.LARAVEL_URL}/assets/images/licenses/${license.image}`}
              data-fancybox={`group-${license.id}`}
            >
              <img
                src={`${Constants.LARAVEL_URL}/assets/images/licenses/${license.image}`}
                alt=""
              />
            </Link>
          ))
        ) : (
          <p>Нет данных</p> // Если лицензий определенного типа нет
        );
      };
    
      return (
        <div className="licenseContent_wrap">
          {licenseTypes.map(({ type, title }) => (
            <div key={type}>
              <h4>{title}</h4>
                <div className='license__content'>
                    {renderLicensesByType(type)}
                </div>
            </div>
          ))}
        </div>
      );
}

export default LicenseGallery
